export const TeacherContent = [
  {
    name: "GROUP SESSION",
    profession: "Location: ABU DHABI",
    video: "https://player.vimeo.com/video/867484655?h=128f824ac2"
  },
  {
    name: "PRIVATE SESSION",
    profession: "Location: AL AIN",
    video: "https://player.vimeo.com/video/867484740?h=c6f6723b1c"
  },
  {
    name: "ASSESSMENT",
    profession: "location: DUBAI",
    video: "https://player.vimeo.com/video/867484770?h=63050862ad"
  },
  {
    name: "GROUP SESSION",
    profession: "Location: ABU DHABI",
    video: "https://player.vimeo.com/video/867484655?h=128f824ac2"
  },

  {
    name: "PRIVATE SESSION",
    profession: "Location: AL AIN",
    video: "https://player.vimeo.com/video/867484740?h=c6f6723b1c"
  },

  {
    name: "ASSESSMENT",
    profession: "location: DUBAI",
    video: "https://player.vimeo.com/video/867484770?h=63050862ad"
  },
];
